.authloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9;
  .authLoader {
    margin-bottom: 8px;
  }
}
.navactive .MuiButtonBase-root {
  background-color: rgba(255, 255, 255, 0.08);
}
.loginWrapper {
  height: 100vh;
  width: 100%;
  background: url("./Assets/login_bg.jpg") center no-repeat;
  background-size: cover;
  .loginCard {
    height: 75vh;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    border-radius: 10px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .loginFormWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      min-height: 200px;
      
    }
  }
}
.nameInput{
  // padding: 12px 15px;
  // font-size: 15px;
  // position: relative;
  text-align: center;
  color: #000;
  margin-top: 25px;
}
.otherInputs{
  text-align: center;
  color: #000;
  margin-top: 10px;
}
.closeBtn{
  margin: 20px 20px 20px 4px;
    position: relative;
    left: 357px;
    top: 23px;
}
.profileImg{
  position: relative;
  left: 163px;
  top: 15px;
  border-radius: 100%;
}
.currentPass{
  width: 100%;
    height: 45px;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid #aca5a5;
    padding-left: 15px;
}
.loginFormWrapperPass {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 200px;
  margin-top: 12px;
}
.submitChangePass{
  position: relative;
  top: 20px;
  right: 210px;
}
.cancelChangePass{
  position: relative;
  right: 100px;
  bottom: 18px;
}
.CategoryWrapper{
 color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #FFF;
    // box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    margin-top: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    flex-direction: column;

}

.selectMerchant {
    padding: 10px 20px;
    margin-top: 25px;
    margin-left: 25px;
}

.startDate {
  padding: 10px 5px;
  margin-top: 25px;
  margin-left: 15px;
}

.endDate {
  padding: 10px 5px;
  margin-top: 25px;
  margin-left: 3px;
}

.settleBtn {
  padding: 10px 58px;
  margin-left: 25px;
}