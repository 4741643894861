.authloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9;
}

.authloaderWrapper .authLoader {
  margin-bottom: 8px;
}

.navactive .MuiButtonBase-root {
  background-color: rgba(255, 255, 255, 0.08);
}

.loginWrapper {
  height: 100vh;
  width: 100%;
  background: url("./Assets/login_bg_dash1.jpg") center no-repeat;
  background-size: cover;
}

.loginWrapper .loginCard {
  height: 75vh;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  border-radius: 10px;
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.loginWrapper .loginCard .loginFormWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 200px;
}

.nameInput {
  text-align: center;
  color: #000;
  margin-top: 25px;
}

.otherInputs {
  text-align: center;
  color: #000;
  margin-top: 10px;
}

.closeBtn {
  margin: 20px 20px 20px 4px;
  position: relative;
  left: 357px;
  top: 23px;
}

.profileImg {
  position: relative;
  left: 163px;
  top: 15px;
  border-radius: 100%;
}

.currentPass {
  width: 100%;
  height: 45px;
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid #aca5a5;
  padding-left: 15px;
}

.loginFormWrapperPass {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 200px;
  margin-top: 12px;
}

.submitChangePass {
  position: relative;
  top: 20px;
  right: 210px;
}

.cancelChangePass {
  position: relative;
  right: 100px;
  bottom: 18px;
}

.CategoryWrapper {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #FFF;
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.selectMerchant {
  padding: 10px 20px;
  margin-top: 25px;
  margin-left: 25px;
}

.startDate {
  padding: 10px 5px;
  margin-top: 25px;
  margin-left: 15px;
}

.endDate {
  padding: 10px 5px;
  margin-top: 25px;
  margin-left: 3px;
}

.settleBtn {
  padding: 10px 58px;
  margin-left: 25px;
}
.modal{
  background-color: #fff;
  border-radius: 5px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.changeColor{
  color: #000;
  text-align: center;
}
